<template>
  <div class="container-fluid bg-dark px-5 d-none d-lg-block">
    <div class="row gx-0">
      <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <small class="me-3 text-light"
            ><i class="fa fa-map-marker-alt me-2"></i>123 Street, Kisimenti,
            Kigali, RWANDA</small
          >
          <small class="me-3 text-light"
            ><i class="fa fa-phone-alt me-2"></i>+012 345 6789</small
          >
          <small class="text-light"
            ><i class="fa fa-envelope-open me-2"></i>info@example.com</small
          >
        </div>
      </div>
      <div class="col-lg-4 text-center text-lg-end">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-twitter fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-facebook-f fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-linkedin-in fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-instagram fw-normal"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
            href=""
            ><i class="fab fa-youtube fw-normal"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar & Hero Start -->
  <div class="container-fluid position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
      <a href="" class="navbar-brand p-0">
        <h1 class="text-primary m-0">
          <i class="fa fa-map-marker-alt me-3"></i>Savy Tour & Travel Agency
        </h1>
        <!-- <img src="img/logo.png" alt="Logo"> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto py-0">
          <a href="/" class="nav-item nav-link">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <a href="/services" class="nav-item nav-link">Services</a>
          <a href="/packages" class="nav-item nav-link">Packages</a>
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              >Pages</a
            >
            <div class="dropdown-menu m-0">
              <a href="/destination" class="dropdown-item">Destination</a>
              <a href="/booking" class="dropdown-item">Booking</a>
            </div>
          </div>
          <a href="/contact" class="nav-item nav-link">Contact</a>
        </div>
        <a href="" class="btn btn-primary rounded-pill py-2 px-4">Register</a>
      </div>
    </nav>

    <div class="container-fluid bg-primary py-5 mb-5 hero-header">
      <div class="container py-5">
        <div class="row justify-content-center py-5">
          <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              Enjoy Your Vacation With Us
            </h1>
            <p class="fs-4 text-white mb-4 animated slideInDown">
              Tempor erat elitr rebum at clita diam amet diam et eos erat ipsum
              lorem sit
            </p>
            <div class="position-relative w-75 mx-auto animated slideInDown">
              <input
                class="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Eg: Akagera"
              />
              <button
                type="button"
                class="btn btn-primary rounded-pill py-2 px-4 position-absolute top-0 end-0 me-2"
                style="margin-top: 7px"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
