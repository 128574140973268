<template>
  <!-- Topbar Start -->
  <HeaderItem />
  <!-- Navbar & Hero End -->

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay="0.1s"
          style="min-height: 400px"
        >
          <div class="position-relative h-100">
            <img
              class="img-fluid position-absolute w-100 h-100"
              src="img/about.jpg"
              alt=""
              style="object-fit: cover"
            />
          </div>
        </div>
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
          <h6 class="section-title bg-white text-start text-primary pe-3">
            About Us
          </h6>
          <h2 class="mb-4">
            Welcome to
            <span class="text-primary">Savy Tour & Travel Agency</span>
          </h2>
          <p class="mb-4">
            Savy Tour and Travel Agency is your gateway to unforgettable travel
            experiences. With a passion for crafting exceptional journeys, we
            specialize in curating personalized travel packages that cater to
            every wanderlust soul. Our agency prides itself on providing bespoke
            itineraries, ensuring each trip is a unique blend of adventure,
            cultural exploration, and relaxation.
          </p>
          <div class="row gy-2 gx-4 mb-4">
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>First Class
                Flights
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Handpicked
                Hotels
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>5 Star
                Accommodations
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Latest Model
                Vehicles
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>150 Premium
                City Tours
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>24/7 Service
              </p>
            </div>
          </div>
          <a class="btn btn-primary py-3 px-5 mt-2" href="">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Service Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Services
        </h6>
        <h1 class="mb-5">Our Services</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-globe text-primary mb-4"></i>
              <h5>Country Tours</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-hotel text-primary mb-4"></i>
              <h5>Hotel Reservation</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-user text-primary mb-4"></i>
              <h5>Travel Guides</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-cog text-primary mb-4"></i>
              <h5>Tour Packaging</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-globe text-primary mb-4"></i>
              <h5>Airline Ticket</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-hotel text-primary mb-4"></i>
              <h5>Hotel Reservation</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-user text-primary mb-4"></i>
              <h5>Travel Guides</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
          <div class="service-item rounded pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-cog text-primary mb-4"></i>
              <h5>Event Management</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service End -->

  <!-- Destination Start -->
  <div class="container-xxl py-5 destination">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Destination
        </h6>
        <h1 class="mb-5">Popular Destination</h1>
      </div>
      <div class="row g-3">
        <div class="col-lg-7 col-md-6">
          <div class="row g-3">
            <div class="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/BISATE-LODGE-BY-WILDERNESS-SAFARIS-VOLCANOES-NATIONAL-PARK.webp" alt="" />
                <div
                  class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2"
                >
                  30% OFF
                </div>
                <div
                  class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2"
                >
                  Bisate Lodge
                </div>
              </a>
            </div>
            <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/ruhondo.jpeg" alt="" />
                <div
                  class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2"
                >
                  25% OFF
                </div>
                <div
                  class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2"
                >
                  Ruhondo Lakes
                </div>
              </a>
            </div>
            <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/00-Akagera_National_Park_Rwanda-BW-header1200px.jpeg" alt="" />
                <div
                  class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2"
                >
                  35% OFF
                </div>
                <div
                  class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2"
                >
                  Akagera
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-5 col-md-6 wow zoomIn"
          data-wow-delay="0.7s"
          style="min-height: 350px"
        >
          <a class="position-relative d-block h-100 overflow-hidden" href="">
            <img
              class="img-fluid position-absolute w-100 h-100"
              src="img/rwanda-terraced-hills.jpeg"
              alt=""
              style="object-fit: cover"
            />
            <div
              class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2"
            >
              20% OFF
            </div>
            <div
              class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2"
            >
              Teracced Hills
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Destination Start -->

  <!-- Package Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Packages
        </h6>
        <h1 class="mb-5">Awesome Packages</h1>
      </div>
      <div class="row g-4 justify-content-center">
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="package-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/package-1.jpg" alt="" />
            </div>
            <div class="d-flex border-bottom">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt text-primary me-2"></i
                >Akagera</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt text-primary me-2"></i>3
                days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>2 Person</small
              >
            </div>
            <div class="text-center p-4">
              <h3 class="mb-0">$149.00</h3>
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
              </div>
              <p>
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <div class="d-flex justify-content-center mb-2">
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="package-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/package-2.jpg" alt="" />
            </div>
            <div class="d-flex border-bottom">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt text-primary me-2"></i
                >Nyungwe</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt text-primary me-2"></i>3
                days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>2 Person</small
              >
            </div>
            <div class="text-center p-4">
              <h3 class="mb-0">$139.00</h3>
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
              </div>
              <p>
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <div class="d-flex justify-content-center mb-2">
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="package-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/package-3.jpg" alt="" />
            </div>
            <div class="d-flex border-bottom">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-map-marker-alt text-primary me-2"></i
                >Virunga</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-calendar-alt text-primary me-2"></i>3
                days</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>2 Person</small
              >
            </div>
            <div class="text-center p-4">
              <h3 class="mb-0">$189.00</h3>
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
              </div>
              <p>
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <div class="d-flex justify-content-center mb-2">
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Package End -->

  <!-- Booking Start -->
  <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">
      <div class="booking p-5">
        <div class="row g-5 align-items-center">
          <div class="col-md-6 text-white">
            <h6 class="text-white text-uppercase">Booking</h6>
            <h1 class="text-white mb-4">Online Booking</h1>
            <p class="mb-4">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
              diam amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
            <p class="mb-4">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
              diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
              lorem sit clita duo justo magna dolore erat amet
            </p>
            <a class="btn btn-outline-light py-3 px-5 mt-2" href=""
              >Read More</a
            >
          </div>
          <div class="col-md-6">
            <h1 class="text-white mb-4">Book A Tour</h1>
            <form>
              <div class="row g-3">
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control bg-transparent"
                      id="name"
                      placeholder="Your Name"
                    />
                    <label for="name">Your Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control bg-transparent"
                      id="email"
                      placeholder="Your Email"
                    />
                    <label for="email">Your Email</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="form-floating date"
                    id="date3"
                    data-target-input="nearest"
                  >
                    <input
                      type="text"
                      class="form-control bg-transparent datetimepicker-input"
                      id="datetime"
                      placeholder="Date & Time"
                      data-target="#date3"
                      data-toggle="datetimepicker"
                    />
                    <label for="datetime">Date & Time</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-select bg-transparent" id="select1">
                      <option value="1">Destination 1</option>
                      <option value="2">Destination 2</option>
                      <option value="3">Destination 3</option>
                    </select>
                    <label for="select1">Destination</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      class="form-control bg-transparent"
                      placeholder="Special Request"
                      id="message"
                      style="height: 100px"
                    ></textarea>
                    <label for="message">Special Request</label>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-outline-light w-100 py-3"
                    type="submit"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Booking Start -->

  <!-- Process Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Process
        </h6>
        <h1 class="mb-5">3 Easy Steps</h1>
      </div>
      <div class="row gy-5 gx-4 justify-content-center">
        <div
          class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="position-relative border border-primary pt-5 pb-4 px-4">
            <div
              class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-globe fa-3x text-white"></i>
            </div>
            <h5 class="mt-4">Choose A Destination</h5>
            <hr class="w-25 mx-auto bg-primary mb-1" />
            <hr class="w-50 mx-auto bg-primary mt-0" />
            <p class="mb-0">
              Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam
              eos erat ipsum et lorem et sit sed stet lorem sit
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="position-relative border border-primary pt-5 pb-4 px-4">
            <div
              class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-dollar-sign fa-3x text-white"></i>
            </div>
            <h5 class="mt-4">Pay Online</h5>
            <hr class="w-25 mx-auto bg-primary mb-1" />
            <hr class="w-50 mx-auto bg-primary mt-0" />
            <p class="mb-0">
              Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam
              eos erat ipsum et lorem et sit sed stet lorem sit
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="position-relative border border-primary pt-5 pb-4 px-4">
            <div
              class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
              style="width: 100px; height: 100px"
            >
              <i class="fa fa-plane fa-3x text-white"></i>
            </div>
            <h5 class="mt-4">Fly Today</h5>
            <hr class="w-25 mx-auto bg-primary mb-1" />
            <hr class="w-50 mx-auto bg-primary mt-0" />
            <p class="mb-0">
              Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam
              eos erat ipsum et lorem et sit sed stet lorem sit
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Process Start -->

  <!-- Team Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Travel Guide
        </h6>
        <h1 class="mb-5">Meet Our Guide</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="team-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-1.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -19px"
            >
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Full Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="team-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-2.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -19px"
            >
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Full Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="team-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-3.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -19px"
            >
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Full Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
          <div class="team-item">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-4.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -19px"
            >
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-square mx-1" href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Full Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Team End -->

  <!-- Testimonial Start -->
  <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">
      <div class="text-center">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Testimonial
        </h6>
        <h1 class="mb-5">Our Clients Say!!!</h1>
      </div>
      <div class="owl-carousel testimonial-carousel position-relative">
        <div class="testimonial-item bg-white text-center border p-4">
          <img
            class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
            src="img/testimonial-1.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">John Doe</h5>
          <p>New York, USA</p>
          <p class="mb-0">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
        </div>
        <div class="testimonial-item bg-white text-center border p-4">
          <img
            class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
            src="img/testimonial-2.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">John Doe</h5>
          <p>New York, USA</p>
          <p class="mt-2 mb-0">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
        </div>
        <div class="testimonial-item bg-white text-center border p-4">
          <img
            class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
            src="img/testimonial-3.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">John Doe</h5>
          <p>New York, USA</p>
          <p class="mt-2 mb-0">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
        </div>
        <div class="testimonial-item bg-white text-center border p-4">
          <img
            class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
            src="img/testimonial-4.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">John Doe</h5>
          <p>New York, USA</p>
          <p class="mt-2 mb-0">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
            amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->

  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Company</h4>
          <a class="btn btn-link" href="">About Us</a>
          <a class="btn btn-link" href="">Contact Us</a>
          <a class="btn btn-link" href="">Privacy Policy</a>
          <a class="btn btn-link" href="">Terms & Condition</a>
          <a class="btn btn-link" href="">FAQs & Help</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Contact</h4>
          <p class="mb-2">
            <i class="fa fa-map-marker-alt me-3"></i>123 Street, Kigali, RWANDA
          </p>
          <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
          <p class="mb-2">
            <i class="fa fa-envelope me-3"></i>info@example.com
          </p>
          <div class="d-flex pt-2">
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-twitter"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-youtube"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Gallery</h4>
          <div class="row g-2 pt-2">
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-1.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-2.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-3.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-2.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-3.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/package-1.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Newsletter</h4>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div class="position-relative mx-auto" style="max-width: 400px">
            <input
              class="form-control border-primary w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; <a class="border-bottom" href="#">Your Site Name</a>, All
            Right Reserved.

            <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
            <!-- Designed By <a class="border-bottom" href="https://htmlcodex.com">HTML Codex</a> -->
          </div>
          <div class="col-md-6 text-center text-md-end">
            <div class="footer-menu">
              <a href="">Home</a>
              <a href="">Cookies</a>
              <a href="">Help</a>
              <a href="">FQAs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
    ><i class="bi bi-arrow-up"></i
  ></a>
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
export default {
  components: {
    HeaderItem
  }
}
</script>
